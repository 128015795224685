<!--
 * @Author: mjjh
 * @LastEditTime: 2024-12-28 11:31:15
 * @FilePath: \chagpt-shuowen\src\App.vue
 * @Description: 根vue
-->
<script setup lang="ts">
import { NConfigProvider } from 'naive-ui'
import { NaiveProvider } from '@/components/index'
import { useTheme } from '@/hooks/useTheme'
import { useLanguage } from '@/hooks/useLanguage'
const { theme } = useTheme()
const { language } = useLanguage()
const themeOverrides = {
    common: {
      primaryColor: '#FFB814'
    },
    Button: {
      primaryColor: '#FFB814',
      infoColor: '#FFB814'
    },
    Select: {
      peers: {
        InternalSelection: {
          textColor: '#FF0000'
        }
      }
    }
    // ...
  }

</script>

<template>
  <NConfigProvider class="h-full" :theme="theme" :theme-overrides="themeOverrides" :locale="language">
    <NaiveProvider>
      <RouterView />
    </NaiveProvider>
  </NConfigProvider>
</template>
<style lang="less">
#app .h-full {
  height: calc(100vh - 20px);
}
#app .h-full-default {
  height: calc(100vh - 200px);
}
@media screen and (max-width: 630px) {
  #app .h-full {
    height: calc(100vh - 50px);
  }
}

.mobile-slider {
  margin-left: 50px;
}

.h-full .n-layout-sider.n-layout-sider--absolute-positioned {
  position: relative;
}
.user-member-box {
  position: relative;
  top: -20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
}

.n-button {
    margin: 0 10px;
    background: rgb(50, 50, 50);
    border: none !important;
    outline: none;
    --n-border: none !important;
    border-radius: 12px;
    height: 28px;
    padding: 0 10px;
    --n-color: rgb(50, 50, 50) !important;
    --n-color-hover: rgb(50, 50, 50) !important;
    --n-color-pressed: rgb(50, 50, 50) !important;
    --n-color-focus: rgb(50, 50, 50) !important;
    --n-color-disabled: rgb(50, 50, 50) !important;
    --n-ripple-color: rgb(50, 50, 50) !important;
    --n-border-hover:none;
    --n-border-pressed: none;
    --n-border-focus:none;
    --n-border-disabled: none;
    span {
        font-size: 12px;
        color: #fff;
    }
    &:focus,&:active {
        background: rgb(50, 50, 50) !important;
        --n-border: none !important;
    }
}
p,span,h1,h2,h3,h4,h5,h6,strong,small,ul,li {
  color: #333;
}

.v-binder-follower-container{
  z-index: 9999 !important;
}
</style>